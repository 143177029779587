
.biography-box {
    font-size: 0.8rem;
    padding: 1rem 0 0;
}

.biography-box > div:first-child {
    font-size: 0.8rem;
    font-weight: bold;
    padding-bottom: 0.5rem;
}

.biography-box div:first-child span:nth-child(2) {
    color: var(--curriculum-theme) !important;
}
