
.avatar {
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
}

.avatar-editable {
    cursor: pointer;
}

.avatar-croppable {
    width: 12rem;
    height: 12rem;
    background-color: white;
}

/* TabMyCV */

#tab5 button {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    margin: 1px;
    border-radius: 50%;
    border: 1px solid black;
}

#tab5  button.active {
    border: 5px solid black;
}

#tab5 .btn-red {
    background-color: var(--curriculum-theme-red);
}

#tab5 .btn-green {
    background-color: var(--curriculum-theme-green);
}

#tab5 .btn-blue {
    background-color: var(--curriculum-theme-blue);
}

#tab5 .btn-yellow {
    background-color: var(--curriculum-theme-yellow);
}
