.curriculum {

    --curriculum-theme-red: #e5332a;
    --curriculum-theme-green: #2fcb00;
    --curriculum-theme-blue: #3c6efd;
    --curriculum-theme-yellow: #f9cd39;

    /* --curriculum-theme: var(--curriculum-theme-red); => managed by CurriculumView in useEffect */

    --curriculum-bg-gray: #dee2e6;
    --curriculum-text-gray: #6c757d;
    --curriculum-text-light-gray: #adb5bd;
    --curriculum-page-shadow: #ced4da;
}

.page {

    font-family: Arial, Helvetica, sans-serif;

    font-size: 0.75rem;
    line-height: 1rem;

    width: 210mm !important;
    min-width: 210mm;
    max-width: 210mm;

    height: 297mm !important;
    min-height: 297mm;
    max-height: 297mm;

    margin: 0 auto 10mm;
    padding: 1rem;

    overflow: hidden;
    box-shadow: 0 0 20px 4px var(--curriculum-page-shadow);
}

.page-top-bar {
    position: relative;
    top: -1rem;
    left: -0.75rem;
    width: 14rem;
    background: var(--curriculum-theme);
}

.page-top-count {
    position: relative;
    top: -1rem;
    left: -0.75em;
    padding-left: 0.4rem;
    font-size: 0.65rem;
    color: var(--curriculum-theme);
}

.section-title {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--curriculum-text-gray);
}

.skill-category {
    font-size: 0.75rem;
    font-weight: 600;
    margin: 0.5rem 0;
    color: var(--curriculum-text-gray);
}

.formation-box,
.language-box,
.reference-box,
    .hobbies-box {
    padding-top: 1.5rem;
    text-align: right;
}

/* printing styles */

@media print {

    .page {

        box-shadow: unset;
        print-color-adjust: exact;

        padding: 0;
        margin: 0;

        min-width: 210mm !important;
        max-width: 210mm !important;

        min-height: 297mm !important;
        max-height: 297mm !important;
    }

    .title-bar,
    .title-bar+hr {
        display: none !important;
    }

    /**
      * browser detection ref
      * https://dev.to/morewings/lets-create-a-simple-react-hook-to-detect-browsers-and-their-capabilities-4lnf
      */

    /** safari */
    @supports(-webkit-hyphens:none) {
        .page {
            min-height: 306mm !important;
            max-height: 306mm !important;

        }
    }

    /** chrome */
    @supports(not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:"*") {
        .page {
            position: relative;
            left: -2cm;
            top: -1mm;
        }
    }

    /** firefox */
    @supports(-moz-appearance:none) {
        .page {
            position: relative;
            top: -2mm;
            left: -2cm;
        }
    }

    /** edge */
    @supports (-ms-ime-align: auto) {
        .page {
            position: relative;
            top: -2mm;
            left: -2cm;
        }
    }

}