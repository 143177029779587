
.legend {
    display: flex;
}

.legend .keys {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    font-size: 0.5rem;
    line-height: 0.6rem;
    color: var(--curriculum-text-gray);
}

.legend .levels {
    flex: 1;
    display: flex;
    align-items: flex-start;
    gap: 0 0.5rem;
    font-size: 0.55rem;
    color: var(--curriculum-text-gray);
}
