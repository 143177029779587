
.language-section {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.language-section img {
    width: 0.6rem;
    height: 0.6rem;
    margin-left: 0.3rem;
    margin-right: 0.1rem;
}