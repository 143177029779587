
.user-box {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bold;
    padding-top: 2rem;
    padding-left: 2rem;
    color: var(--curriculum-theme);
}

.user-box div:nth-child(2) {
    padding-top: 1rem;
}

.contact-box {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem 3rem;
    font-size: 0.7rem;;
}

.contact-box img {
    width: 0.8rem;
    height: 0.8rem;
}

.contact-box span {
    margin-left: 0.5rem;
    color: var(--curriculum-text-gray);
}

/* availability field */
.contact-box > div:last-child span {
    padding: 0.2rem 1rem;
    color: var(--curriculum-theme);
    background-color: var(--curriculum-bg-gray);

}