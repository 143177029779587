
.level-bar {
    display: flex;
    align-items: center;
}

.level-bar div:first-child {
    display: flex;
    padding-right: 0.4rem;
}

.level-bar .spill {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    margin: 1px;
    border-radius: 50%;
    background-color: var(--curriculum-theme);
}

.level-bar .spill.inactive {
    background-color: var(--curriculum-bg-gray);
} 

.level-bar .disabled {
    color: var(--curriculum-text-light-gray);
} 
